import Swiper from 'swiper/bundle';

// AI協調フィルタリング
const aiRecommend = document.querySelector('#ai_recommend');
if (aiRecommend) {
  $.ajax({
    url: '/recommends',
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    type: 'GET',
    timeout: 5000,
  });
}

// トップMV swiper
const topMvSwiperElm = document.querySelector('[data-role="top-mv-swiper"]');
if (topMvSwiperElm !== null) {
  let options = [];
  let swiperItems = topMvSwiperElm.querySelectorAll('.swiper-slide').length;

  if (swiperItems > 1) {
    options = {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      allowTouchMove: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      speed: 1500,
      pagination: {
        el: "[data-role='top-mv-swiper-pagination']",
        clickable: true,
      },
    };
  } else {
    options = {
      loop: false,
      autoplay: false,
    };
  }
  // eslint-disable-next-line no-new
  let topMvSwiper = new Swiper(topMvSwiperElm, options);
}

// おすすめswiper
const topSectionSwiperBuild = () => {
  const topSectionSwiperElms = document.querySelectorAll('[data-role="top-section-contents-swiper"]');
  if (topSectionSwiperElms.length) {
    topSectionSwiperElms.forEach((elm) => {
      let topSectionSwiper = new Swiper(elm, {
        slidesPerView: 1.8,
        spaceBetween: 16,
        allowTouchMove: true,
        centerInsufficientSlides: true,
        centeredSlides: true,
        speed: 1500,
        breakpoints: {
          768: {
            slidesPerView: 2.4,
            spaceBetween: 16,
            centeredSlides: false,
            slidesPerGroup: 2,
            slidesOffsetBefore: 40,
            slidesOffsetAfter: 40,
          },
          1012: {
            slidesPerView: 3.5,
            spaceBetween: 24,
            centeredSlides: false,
            slidesPerGroup: 3,
            slidesOffsetBefore: 40,
            slidesOffsetAfter: 40,
          },
        },
        navigation: {
          nextEl: '[data-role="top-section-contents-swiper-nav-next"]',
          prevEl: '[data-role="top-section-contents-swiper-nav-prev"]',
        },
      });
    });
  }
};
topSectionSwiperBuild();

// AIからのおすすめを監視、中身が変わればswiperを発火
const topAiRecommend = document.querySelector('#ai_recommend');
if (topAiRecommend !== null) {
  const observer = new MutationObserver(function () {
    topSectionSwiperBuild();
  });
  const config = {
    attributes: true,
    childList: true,
    characterData: true,
  };
  observer.observe(topAiRecommend, config);
}
const topSectionSwiperWrap = document.querySelector('#recommend_spot_1');
if (topSectionSwiperWrap !== null) {
  const observer = new MutationObserver(function () {
    topSectionSwiperBuild();
  });
  const config = {
    attributes: true,
    childList: true,
    characterData: true,
  };
  observer.observe(topSectionSwiperWrap, config);
}

// 今週の閲覧数TOP10
const topRanking = document.querySelector('[data-role="top-ranking"]');

if (topRanking !== null) {
  const topRankingSwiper = new Swiper('.top-ranking-swiper', {
    slidesPerView: 1.8,
    spaceBetween: 16,
    allowTouchMove: true,
    centerInsufficientSlides: true,
    centeredSlides: true,
    speed: 1500,
    breakpoints: {
      768: {
        slidesPerView: 2.4,
        spaceBetween: 16,
        centeredSlides: false,
        slidesPerGroup: 2,
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
      },
      1012: {
        slidesPerView: 3.5,
        spaceBetween: 24,
        centeredSlides: false,
        slidesPerGroup: 3,
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
      },
    },
    navigation: {
      nextEl: '[data-role="top-ranking-swiper-nav-next"]',
      prevEl: '[data-role="top-ranking-swiper-nav-prev"]',
    },
    on: {
      init: function () {
        /* eslint-disable */
        const initData = getSlideData(this);
        rankingInfoDisplay(initData);
        /* eslint-enable */
      },
      slideChangeTransitionStart: function () {
        document.querySelector('.top-ranking-contents').classList.remove('is-show');
      },
      slideChangeTransitionEnd: function () {
        /* eslint-disable */
        const slidedData = getSlideData(this);
        rankingInfoDisplay(slidedData);
        /* eslint-enable */
      },
    },
  });

  const rankingDataWrap = document.querySelector('.top-ranking-contents');
  const swiperItems = document.querySelectorAll('.top-ranking-swiper .swiper-slide');

  swiperItems.forEach((elm) => {
    elm.addEventListener('click', () => {
      // eslint-disable-next-line
      setActiveSlide(elm);
    });
  });

  function getSlideData(swiper) {
    const slideData = [];
    const activeIndex = swiper.activeIndex;
    const activeSlide = swiper.slides[activeIndex];
    slideData.url = activeSlide.querySelector('.ranking-url').value;
    slideData.title = activeSlide.querySelector('.ranking-title').value;
    slideData.description = activeSlide.querySelector('.ranking-description').value;
    slideData.imagePath = activeSlide.querySelector('.top-ranking-item__img img').getAttribute('src');
    return slideData;
  }

  function setActiveSlide(elm) {
    const swiperItems = document.querySelectorAll('.top-ranking-swiper .swiper-slide');
    const activeData = [];

    swiperItems.forEach((otherElm) => {
      otherElm.classList.remove('swiper-slide-active');
    });

    elm.classList.add('swiper-slide-active');

    activeData.url = elm.querySelector('.ranking-url').value;
    activeData.title = elm.querySelector('.ranking-title').value;
    activeData.description = elm.querySelector('.ranking-description').value;
    activeData.imagePath = elm.querySelector('.top-ranking-item__img img').getAttribute('src');

    rankingDataWrap.classList.remove('is-show');

    setTimeout(() => {
      // eslint-disable-next-line
      rankingInfoDisplay(activeData);
    }, 800);
  }

  function rankingInfoDisplay(dispData) {
    const rankingDataWrap = document.querySelector('.top-ranking-contents');
    const rankingTitleArea = document.querySelector('.top-ranking-contents__title');
    const rankingDescriptionArea = document.querySelector('.top-ranking-contents__description');
    const rankingImageArea = document.querySelector('.top-ranking-contents__bg');
    const rankingUrlArea = document.querySelector('.top-ranking-contents__btn');

    while (rankingTitleArea.firstChild) {
      rankingTitleArea.removeChild(rankingTitleArea.firstChild);
    }

    while (rankingDescriptionArea.firstChild) {
      rankingDescriptionArea.removeChild(rankingDescriptionArea.firstChild);
    }

    while (rankingImageArea.firstChild) {
      rankingImageArea.removeChild(rankingImageArea.firstChild);
    }

    rankingTitleArea.textContent = dispData.title;
    rankingDescriptionArea.textContent = dispData.description;

    const rankingBgElm = document.createElement('img');
    rankingBgElm.src = dispData.imagePath;
    rankingImageArea.appendChild(rankingBgElm);

    rankingUrlArea.querySelector('a').href = dispData.url;
    rankingDataWrap.classList.add('is-show');
  }
}
