import ArchiveFilter from './components/ArchiveFilter/ArchiveFilter';
import SwiperSingle from './components/singleSwiper';
import SwiperArticleList from './components/articleListSwiper';

// ArchiveFilter
//
// アーカイブ一覧ページにおける絞り込みフォーム要素パーツ
if (document.querySelector('[data-role="archive-filter"]')) {
  if (document.querySelector('.archive-filter-area-map')) {
    // eslint-disable-next-line no-new
    new ArchiveFilter();
  } else {
    // eslint-disable-next-line no-new
    new ArchiveFilter({ disableAreaMapMode: true });
  }
}

// グローバルナビ
if (document.querySelector('.global-header') !== null) {
  let menuFlag = false;
  const globalHeader = document.querySelector('.global-header');
  const overlay = document.createElement('div');
  overlay.className = 'overlay';
  globalHeader.appendChild(overlay);
  document.querySelector('.js-menu-toggle').addEventListener('click', function () {
    if (menuFlag == false) {
      document.querySelector('.global-header').classList.add('is-menu-open');
      this.classList.add('is-menu-open');
      overlay.classList.add('is-active');
      document.querySelector('body').style.overflow = 'hidden';
      menuFlag = true;
    } else {
      document.querySelector('.global-header').classList.remove('is-menu-open');
      this.classList.remove('is-menu-open');
      overlay.classList.remove('is-active');
      document.querySelector('body').style.overflow = 'visible';
      menuFlag = false;
    }
  });
  overlay.addEventListener('click', function () {
    document.querySelector('.js-menu-toggle').classList.remove('is-menu-open');
    this.classList.remove('is-active');
    menuFlag = false;
  });
  // 言語選択ボタン
  const languageSwitch = document.querySelector('[data-role="language-switch"]');
  languageSwitch.addEventListener('click', () => {
    document.querySelector('.locale-list').classList.toggle('is-show');
  });
}

// tab
const cmnTabElms = document.querySelectorAll('[data-role="cmn-tab"]');
if (cmnTabElms.length) {
  cmnTabElms.forEach((elm) => {
    const tabBtnsArea = elm.querySelector('[role="tablist"]');
    tabBtnsArea.addEventListener('click', (ev) => {
      const tabBtnElm = ev.target;
      if (tabBtnElm.hasAttribute('data-target')) {
        let tabTarget;
        let tabCurrentTarget = tabBtnElm.getAttribute('data-target');

        // ボタン切り替え
        const tabBtns = elm.querySelectorAll('[data-role="tabbtn"]');
        tabBtns.forEach((tabBtn) => {
          tabTarget = tabBtn.getAttribute('data-target');
          if (tabTarget === tabCurrentTarget) {
            tabBtn.classList.add('is-active');
            tabBtn.ariaSelected = true;
            tabBtn.removeAttribute('tabindex');
          } else {
            tabBtn.classList.remove('is-active');
            tabBtn.ariaSelected = false;
            tabBtn.setAttribute('tabindex', '-1');
          }
        });

        // パネル切り替え
        const tabContentsElm = elm.querySelectorAll('[role="tabpanel"]');
        tabContentsElm.forEach((tabContents) => {
          tabTarget = tabContents.getAttribute('id');
          if (tabTarget === tabCurrentTarget) {
            tabContents.classList.add('is-active');
          } else {
            tabContents.classList.remove('is-active');
          }
        });
      }
    });
  });
}

// 1枚画像swiper
const singleSwiperElms = document.querySelectorAll("[data-role='single-swiper']");
if (singleSwiperElms.length) {
  singleSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperSingle({ elm });
  });
}

// 近くのスポット用swiper
const articleListSwiperElms = document.querySelectorAll("[data-role='article-list-swiper']");
if (articleListSwiperElms.length) {
  articleListSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperArticleList({ elm });
  });
}

// おすすめ設定
const settingModal = document.querySelector('.modal-favorite');
const favoriteSettingBtns = document.querySelectorAll("[data-role='favorite-setting']");
if (favoriteSettingBtns.length) {
  favoriteSettingBtns.forEach((elm) => {
    elm.addEventListener('click', () => {
      // 現在表示している内容を削除
      if (settingModal.children.length > 0) {
        settingModal.innerHTML = '';
      }
      if (settingModal !== null) {
        settingModal.classList.add('is-show');
      }
    });
  });
}
function settingBtnClick(event) {
  const targetClassName = String(event.target.className);
  if (targetClassName.indexOf('config-btn') != -1) {
    if (settingModal.children.length > 0) {
      settingModal.innerHTML = '';
    }
    if (settingModal !== null) {
      settingModal.classList.add('is-show');
    }
  }
}
const appendedAreas = document.querySelectorAll('[data-role="monitored-target"]');
if (appendedAreas.length) {
  appendedAreas.forEach((appendedArea) => {
    appendedArea.addEventListener('click', settingBtnClick);
  });
}
