// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Swiper from 'swiper/bundle';

$(() => {
  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchAreaAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="course_area_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[areas_id_in][]"]:checked').length === $('input[name="q[areas_id_in][]"]').length - 1) {
      $('input[name="course_area_id[all]"]').prop('checked', true);
    }
  }

  function switchTagAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="course_tag_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[tags_id_in][]"]:checked').length === $('input[name="q[tags_id_in][]"]').length - 1) {
      $('input[name="course_tag_id[all]"]').prop('checked', true);
    }
  }

  if ($('#course_search').length) {
    // エリア全て選択時、エリアの状態も変更する
    $('input[name="course_area_id[all]"]').click((e) => {
      $('input[name="q[areas_id_in][]"]').prop('checked', e.target.checked);
    });

    // エリア選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[areas_id_in][]"]').click(() => {
      switchAreaAllCheckbox();
    });

    // カテゴリー全て選択時、カテゴリーの状態も変更する
    $('input[name="course_tag_id[all]"]').click((e) => {
      $('input[name="q[tags_id_in][]"]').prop('checked', e.target.checked);
    });

    // カテゴリー選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[tags_id_in][]"]').click(() => {
      switchTagAllCheckbox();
    });
  }
});

// 近くのおすすめ/立ち寄りスポットswiper
const courseRecommendationsSwiperBuild = () => {
  const courseRecommendationsSwiperElms = document.querySelectorAll(
    '[data-role="course-day-spot-recommendations-swiper"]'
  );
  if (courseRecommendationsSwiperElms.length) {
    courseRecommendationsSwiperElms.forEach((elm) => {
      let options = [];
      let swiperItems = elm.querySelectorAll('.swiper-slide').length;
      console.log(swiperItems);

      if (swiperItems > 1) {
        options = {
          slidesPerView: 1,
          spaceBetween: 0,
          loop: true,
          allowTouchMove: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          speed: 1500,
          navigation: {
            nextEl: elm.querySelector("[data-role='course-day-spot-recommendations-swiper-nav-next']"),
            prevEl: elm.querySelector("[data-role='course-day-spot-recommendations-swiper-nav-prev']"),
          },
        };
      } else {
        options = {
          loop: false,
          autoplay: false,
        };
      }

      let courseRecommendationsSwiper = new Swiper(elm.querySelector('.swiper'), options);
    });
  }
};
courseRecommendationsSwiperBuild();

// 各日の要素を監視、中身が変わればswiperを発火
const courseDayWrap = document.querySelector('.course-tab-panel');
if (courseDayWrap !== null) {
  const observer = new MutationObserver(function () {
    courseRecommendationsSwiperBuild();
  });
  const config = {
    attributes: true,
    childList: true,
    characterData: true,
  };
  observer.observe(courseDayWrap, config);
}
