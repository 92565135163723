import Swiper from 'swiper/bundle';

class SwiperSingle {
  constructor({ elm }) {
    let options = [];
    let swiperItems = elm.querySelectorAll('.swiper-slide').length;

    if (swiperItems > 1) {
      options = {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        speed: 1500,
        pagination: {
          el: elm.querySelector("[data-role='single-swiper-pagination']"),
          clickable: true,
        },
        navigation: {
          nextEl: elm.querySelector("[data-role='single-swiper-next']"),
          prevEl: elm.querySelector("[data-role='single-swiper-prev']"),
        },
      };
    } else {
      options = {
        loop: false,
        autoplay: false,
      };
    }

    this.swiper = new Swiper(elm, options);
  }
}

export default SwiperSingle;
