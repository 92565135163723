import AicUi from './AicUi/App';

if (document.querySelector('[data-mp-role="aic-form"]')) {
  // eslint-disable-next-line no-unused-vars
  const aicUi = new AicUi({
    aicAPIBaseURI: `/ai-concierge.js`,
    apiKey: 'dummy_key', // dummy
    place: 'nara_city', // dummy
  });
}
